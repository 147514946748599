@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "./pages/Documents/style.css";

body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
}

code {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

/* HEADER */
.main {
  display: flex;
  align-items: center;
  position: relative;
  background: linear-gradient(360deg, #ffefdd 0%, rgba(255, 255, 255, 0) 100%)
    no-repeat;
}

.logo {
  position: absolute;
  left: 0;
  width: 200px;
  margin: 15px;
}

.banner {
  width: 80%;
  display: flex;
  justify-content: center;
  height: 100vh;
  background-color: rgb(238, 231, 231);
}

.banner-login {
  width: 70%;
}

.right {
  width: 40%;
}

.sign-in-form {
  max-width: 600px;
  margin: 20px auto;
  padding: 30px;
}

.title {
  font-size: 30px;
  margin-bottom: 30px;
  font-weight: 500;
}

.custom-button {
  font-weight: 400;
}

.ps-active {
  background-color: #ff9900;
  color: #fff;
  border-radius: 8px;
  transition: all 0.1s ease;
}

.ps-menu-button:hover {
  background-color: transparent !important;
}

.ant-layout-sider-dark {
  background-color: #303030 !important;
  transition: width 0.1s ease;
  transition: all 0.1s ease;
}

.ant-layout-sider-light {
  background-color: #fff !important;
  transition: width 0.1s ease;
  transition: all 0.1s ease;
}

.ant-layout-sider-light .custom-menu {
  color: #000;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 3px 0;
}

.custom-menu:hover {
  background-color: #ff9900;
  color: #fff;
}

.ps-menu-icon {
  margin: 0 !important;
  padding: 0 !important;
}

.logo-vertical {
  width: 70%;
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
}

.ant-btn-default {
  background: transparent;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.88);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
}

.custom-button:hover {
  color: #fff !important;
  border-color: #ff9900 !important;
  background-color: #ff9900 !important;
}

.custom-button:active {
  color: #fff !important;
  border-color: #ff9900 !important;
  background-color: #ff9900 !important;
}

.custom-button:focus-within {
  color: #fff !important;
  border-color: #ff9900 !important;
  background-color: #ff9900 !important;
}

.ant-spin .ant-spin-dot {
  font-size: 60px;
}

.ant-spin .ant-spin-dot-item {
  background-color: #ff9900 !important;
  width: 24px;
  height: 24px;
}

.ant-input-outlined {
  border-color: #d9d9d9;
}

.ant-input-outlined:hover {
  border-color: #ff9900;
}

.ant-input-outlined:focus-within {
  border-color: #ff9900;
}

.ant-select.ant-select-outlined {
  border-color: #d9d9d9;
}

.ant-select.ant-select-outlined:hover .ant-select-selector {
  border-color: #ff9900 !important;
}

.ant-select.ant-select-outlined:focus-within .ant-select-selector {
  border-color: #ff9900 !important;
}

.ant-btn.css-dev-only-do-not-override-1qfezbu.ant-btn-default:hover {
  background-color: #ff9900 !important;
  border-color: #ff9900 !important;
  color: #fff !important;
}

.ant-btn-primary[disabled]:hover {
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-btn-primary.ant-btn-dangerous[disabled]:hover {
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-btn-default:hover {
  /* opacity: 0.7 !important;
  border-color: #ff9900 !important;
  background-color: #ff9900 !important;
  color: #fff !important;
  transition: all 0.2s ease-in-out; */
}

.ant-input-affix-wrapper .anticon.ant-input-password-icon,
.anticon.anticon-eye.ant-input-password-icon {
  color: #ff9900 !important;
}

.ant-input-group-wrapper.ant-input-group-wrapper-lg
  span
  .ant-input-group-addon {
  background-color: #ff9900;
  color: #fff;
  border-color: #ff9900;
}

.ant-input-group-wrapper.ant-input-group-wrapper-lg span .ant-input-outlined {
  border-color: #d9d9d9;
  font-size: 0.9rem;
  padding: 8px 11px;
}

.ant-input {
  border-radius: 6px;
}

.ant-btn-primary {
  border-color: #ff9900;
  background-color: #ff9900;
  color: #fff;
}

.darkness-form .ant-form-item {
  margin-bottom: 10px !important; /* Tùy chỉnh khoảng cách */
}

.ant-btn-primary:hover {
  background-color: #ff9900 !important;
  border-color: #ff9900 !important;
  color: #fff !important;
  opacity: 0.7;
}

.ant-btn-primary.list-btn-user[disabled]:hover {
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-btn-primary.list-btn-user:hover {
  background-color: #ff9900 !important;
  border-color: #ff9900 !important;
  color: #fff !important;
  opacity: 0.7;
}

.ant-btn-primary.ant-btn-dangerous {
  border-color: #ff4d4f;
  background-color: #ff4d4f;
  color: #fff;
}

.ant-btn-primary.ant-btn-dangerous:hover {
  background-color: #ff4d4f !important;
  border-color: #ff4d4f !important;
  color: #fff !important;
  opacity: 0.7;
}

.switch-role.ant-switch-checked {
  background-color: #ff9900 !important;
}

.ant-input-group-wrapper.ant-input-group-wrapper-lg
  span
  .ant-input-outlined:hover,
.ant-picker-outlined:hover {
  border-color: #ff9900;
}

.ant-input-group-wrapper.ant-input-group-wrapper-lg
  span
  .ant-input-outlined:focus-within,
.ck.ck-editor__main > .ck-editor__editable:focus-within,
.ant-picker-outlined:focus-within,
.ant-picker-outlined:focus {
  border-color: #ff9900 !important;
}

.ant-picker-range .ant-picker-active-bar {
  background-color: #ff9900;
}

.ant-select.ant-select-lg .ant-select-selector {
  border-radius: 6px !important;
}

.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload.ant-upload-select:hover {
  border: 1px dashed #ff9900 !important;
}

.ant-pagination .ant-pagination-item-active {
  font-weight: 600;
  background-color: #ffffff;
  border-color: #ff9900;
}

.ant-pagination .ant-pagination-item-active a {
  color: #ff9900;
}

.ant-pagination .ant-pagination-item-active a:hover {
  color: #ff9900;
}

.ant-pagination .ant-pagination-item-active:hover {
  font-weight: 600;
  background-color: #ffffff;
  border-color: #ff9900;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ff9900;
}

.ant-tabs .ant-tabs-tab:hover {
  color: #ff9900;
}

.ant-tabs .ant-tabs-ink-bar {
  background-color: #ff9900;
}

.ant-table-wrapper .ant-table-pagination-right {
  display: flex;
  justify-content: center !important;
}

.ant-table-content .ant-table-cell .blue-button {
  background: #2c9ef6;
  border: 1px solid transparent !important;
}
.ant-table-content .ant-table-cell .blue-button:hover {
  background: #2c9ef6 !important;
  opacity: 0.8;
  border: 1px solid #2c9ef6 !important;
}

.spin-uploading .ant-spin-dot.ant-spin-dot-spin {
  font-size: 18px !important;
}
